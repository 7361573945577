<template>
    <div></div>
</template>


<script>

import { removeLocalStorage } from '@/utils/auth'
import { redirectNameTo } from '@/utils/route'

export default {
    name: 'LogoutPage',
    mounted: function(){
        this.logout()
    },
    methods: {
        logout: function() {
            this.$store.dispatch('auth/logout').then(() => {
                removeLocalStorage('blink-data')
                redirectNameTo('epanel-login')
            }).catch(() => {
                this.loading = false
            })
        },
    }
}
</script>